import css from './Summary.module.css';

function formatComment(comment) {
  // Splitting on " means that every odd index
  // in the list is the quoted part of the comment
  let parts = comment.split('"');
  let result = [];
  for(let i = 0; i < parts.length; ++i)
    if(i % 2 === 0)
      result.push(<span key={i}>{parts[i]}</span>);
    else
      result.push(<span key={i} className={css.bold}>"{parts[i]}"</span>);
  return result;
}

function makeSummaryEntry(text, data, underline = true) {
  const val = Math.round(parseFloat(data.val));
  const recommended = data.low === data.high ? data.low : `${data.low} - ${data.high}`;
  const status = data.status.toString() === '1' ? 'Low' : data.status.toString() === '2' ? 'Normal' : 'High';
  const status_color = status === 'High' ? css.red : status === 'Low' ? css.blue : null;
  return (
    <div className={css.entry}>
      <div className={css.category}>
        <div className={css.bold}>{text}</div>
        <div className={css.separator}></div>
        <div className={css.heavy}>{val}</div>
      </div>
      <hr />
      <div className={css.sub_category}>
        <div>Recommended</div>
        <div className={css.separator}></div>
        <div className={css.bold}>{recommended}</div>
      </div>
      <hr />
      <div className={css.sub_category}>
        <div>Status</div>
        <div className={css.separator}></div>
        <div className={`${css.bold} ${status_color}`}>{status}</div>
      </div>
      {underline ? (<hr className={css.large}/>) : ''}
    </div>
  );
}

function Summary(props) {
  const firstName = props.appData.patientData.patient.firstName;
  const asaRes = props.appData.patientData.rulesEngine;
  const calories = { val: asaRes.consumed_goal8, low: asaRes.summ_kcal_LB, high: asaRes.summ_kcal_UB, status: asaRes.summ_status_kcal };
  const fats = { val: asaRes.consumed_goal4, low: asaRes.summ_fat_LB, high: asaRes.summ_fat_UB, status: asaRes.summ_status_fat };
  const carbs = { val: asaRes.summ_carb_consumed, low: asaRes.summ_carb_LB, high: asaRes.summ_carb_UB, status: asaRes.summ_status_carb };
  const proteins = { val: asaRes.summ_pro_consumed, low: asaRes.summ_pro_LB, high: asaRes.summ_pro_UB, status: asaRes.summ_status_pro };

  // 1=Much more than usual; 2=Usual; 3=Much less than usual; 8=Don't know 
  const amountUsual = parseInt(props.appData.patientData.patient.amtUsual);
  const amount = amountUsual === 1 ? 'much more than usual' : amountUsual === 2 ? 'the usual amount' : amountUsual === 3 ? 'much less than usual' : null;
  let comment;
  if(amount)
    comment = `${firstName} commented that they ate "${amount}" on this 24 hour recall.`;
  else
    comment = `${firstName} commented that they "doesn't know the amount eaten compared to the usual" on this 24 hour recall.`

  return (
    <div className={css.summary}>
      <div className={css.title}>Patient Summary</div>
      <hr />
      <div className={css.comment}>{formatComment(comment)}</div>
      <div className={css.label}>Calories</div>
      <div>{makeSummaryEntry('Calories', calories)}</div>
      <div>{makeSummaryEntry('Fat', fats)}</div>
      <div>{makeSummaryEntry('Carbohydrates', carbs)}</div>
      <div>{makeSummaryEntry('Protein', proteins, false)}</div>
      <div className={css.footerSpacer}></div>
      <div className={css.footer}>
        <hr className={css.noteSeparator} />
        <div className={css.note}>*Note* Recommended ranges are personalized based on age and sex using acceptable macronutrient distribution ranges (AMDRs) set by USDA.</div>
      </div>
    </div>
  );
}

Summary.defaultProps = {
  comment: 'Tania commented that she ate "much more than usual" on this 24 hour recall.',
  data: {
    calories: {val: 2453, low: 2200, high: 2200},
    fats: {val: 557, low: 440, high: 660},
    carbs: {val: 1153, low: 770, high: 990},
    proteins: {val: 152, low: 220, high: 770},
  },
  appData: {},
};

export default Summary;