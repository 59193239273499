import axios from 'axios';


const NutriApi = {
  sso: (token) => {
    const url = 'https://sl79qoacq9.execute-api.us-east-1.amazonaws.com/asa24-signon';
    const options = {
      headers: { Authorization: token },
    };
    return axios.get(url, options);
  },
  callback: (token) => {
    const url = 'https://sl79qoacq9.execute-api.us-east-1.amazonaws.com/asa24-callback';
    const options = {
      params: { token: token },
    };
    return axios.get(url, options);
  },
  rulesEngine: (token) => {
    const url = 'https://sl79qoacq9.execute-api.us-east-1.amazonaws.com/rules-engine';
    const options = {
      headers: { Authorization: token },
    };
    return axios.get(url, options);
  },
  updateStatus: (token, name, value) => {
    const url = 'https://sl79qoacq9.execute-api.us-east-1.amazonaws.com/status';
    const options = {
      headers: { Authorization: token },
    };
    const data = { name: name, value: value };
    return axios.post(url, data, options);
  },
};

export default NutriApi;