import React from 'react';
import css from './Alert.module.css';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';


const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    }
  },
});


function Alert(props) {
  const classes = useStyles();
  let titleCss = css.title;

  switch(props.type) {
    case 'info':
      titleCss += ` ${css.green}`
      break;
    default:
      break;
  }

  return (
    <Dialog className={classes.root} onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open}>
      <div className={titleCss}>{props.title}</div>
      <div className={css.message}>{props.message}</div>
    </Dialog>
  );
}

Alert.defaultProps = {
  open: false,
  title: 'Error',
  message: 'Please select only 2 to 3 goals to continue',
  onClose: () => {},
};

export default Alert;