import css from './ReviewPanel.module.css';
import icon from '../icons/check.svg';


function formatStatement(statement) {
  // Splitting on | means that every odd index
  // in the list is the quoted part of the statement
  let parts = statement.split('|');
  let result = [];
  for(let i = 0; i < parts.length; ++i)
    if(i % 2 === 0)
      result.push(<span key={i}>{parts[i]}</span>);
    else
      result.push(<span key={i} className={css.bold}>{parts[i]}</span>);
  return result;
}

function ReviewPanel(props) {
  const firstName = props.appData.patientData.patient.firstName;
  const goal = props.appData.goals.find((goal) => goal.name === props.goal.goal);

  return (
    <div className={css.panel}>
      <div className={css.title}>
        <span>Review {firstName}'s Visit</span>
      </div>
      <div className={css.goal}>
        <div className={css.header}>
          <div className={css.icon}>
            <img src={icon} alt="food-type-icon" />
          </div>
          <div className={css.goalText}>
            <span>{goal.title}</span>
          </div>
        </div>
        <div className={css.body}>
          <div className={css.problem}>
            <div>{formatStatement(goal.quantProblemStatement)}</div>
          </div>
          <div className={css.goalStatement}>
            <div>Goal: {formatStatement(goal.problemGoal)}</div>
          </div>
        </div>  
      </div>
    </div>
  );
}

ReviewPanel.defaultProps = {
  recommendationPercentage: '90%',
  intakePercentage: '12%',
  callback: () => {},
};

export default ReviewPanel;