import React from 'react';
import css from './Asa24Callback.module.css';

import helpers from '../logic/helpers';
import NutriLogo from '../icons/nutri_logo.svg';


function parse_message(message) {
  let parts = message.split('|');
  let result = [];
  for(let i = 0; i < parts.length; ++i){
    if(i % 2 === 0)
      result.push(<span key={i}>{parts[i]}</span>)
    else
      result.push(<a key={i} href={parts[i]} target="_blank" rel="noreferrer">{parts[i]}</a>)
  }
  return result;
}

function Asa24Callback(props) {
  const trans = helpers.getTrans('Asa24Callback');

  return (
    <div className={css.message}>
      <img src={NutriLogo} alt='nutri-logo' />
      <br />
      <div className={css.part}>{parse_message(trans.message.eng)}</div>
      <br /> <br />
      <div className={css.part}>{parse_message(trans.message.spa)}</div>
    </div>
  );
}

export default Asa24Callback;