import React from 'react';
import css from './SharedDecision.module.css';

import helpers from '../logic/helpers';

import Navbar from '../components/Navbar';
import ProgressBar from '../components/ProgressBar';
import GoalDecision from '../components/GoalDecision';
import Button from '../components/Button';
import ButtonToggle from '../components/ButtonToggle';
import Confidence from '../components/Confidence';
import Alert from '../components/Alert';

function SharedDecision(props) {
  const firstName = props.appData.patientData.patient.firstName;

  // Set Title
  React.useEffect(() => { document.title = 'Shared Decision'; });

  // Handle Lang
  const trans = (path) => helpers.getTrans(path)[props.lang] || '';

  // Handle Lang
  let steps = [
    { label: trans('SharedDecision.step1'), step: 0, url: '/recall', sessionKey: 'appData' },
    { label: trans('SharedDecision.step2'), step: 1, url: '/shared', sessionKey: 'recallResult' },
    { label: trans('SharedDecision.step3'), step: 2, url: '/review', sessionKey: 'sharedResult' },
  ];

  let buttonParts = [
    { display: trans('ButtonToggle.english'), value: 'eng' },
    { display: trans('ButtonToggle.spanish'), value: 'spa' }
  ];

  let together = trans('SharedDecision.together').replace('[firstname]', firstName);
  let achieve = trans('SharedDecision.achieve').replace('[firstname]', firstName);

  // Alert
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const handleAlertOpen = (title, message) => (event) => { setAlertTitle(title); setAlertMessage(message); setAlertOpen(true); };
  const handleAlertClose = () => setAlertOpen(false);
  const handleAlert = handleAlertOpen(trans('SharedDecision.error'), trans('SharedDecision.errorMessage'));

  // Handle Goal Selecting
  let goals;
  if(props.lang === 'spa')
    goals = props.appData.goalsSpa.filter((goal) => props.goals.find((goalName) => goalName === goal.name));
  else
    goals = props.appData.goals.filter((goal) => props.goals.find((goalName) => goalName === goal.name));
  const [selectedGoal, setSelectedGoal] = React.useState(null);
  const handleGoalSelect = (name) => () => {
    if(selectedGoal === name)
      setSelectedGoal(null);
    else
      setSelectedGoal(name);
  };
  const isSelected = (name) => {
    return selectedGoal === name;
  };

  // Handle Confidence Level
  const [confidence, setConfidence] = React.useState({});
  const handleConfidence = (goalName) => (level) => {
    let temp = JSON.parse(JSON.stringify(confidence));
    temp[goalName] = level;
    setConfidence(temp);
  };
  const getLevel = (goalName) => {
    return confidence[goalName] || '0';
  };

  // Next Button
  const canContinue = () => { return Boolean(selectedGoal); };
  const handleNext = (event) => {
    if(!canContinue())
      return handleAlert();
    else {
      helpers.sendStatus('sharedPage.next', 'clicked');
      helpers.sendStatus('sharedPage.next.goal', JSON.stringify(selectedGoal));
      helpers.sendStatus('sharedPage.next.confidence', JSON.stringify(confidence));
      props.callback({ goal: selectedGoal, confidence: confidence });
    }
  };

  // Handle Session Storage setting
  React.useEffect(() => {
    const storedResult = JSON.parse(sessionStorage.getItem('sharedResult')) || {};
    setSelectedGoal(storedResult.goal || null);
    setConfidence(storedResult.confidence || {});
  }, []);

  React.useEffect(() => {
    helpers.sendStatus('sharedPage', 'loaded');
  }, []);

  return (
    <div className={css.container}>
      <div>
        <Navbar></Navbar>
      </div>
      <div className={css.content}>
        <div>
          <ProgressBar step={1} appData={props.appData} steps={steps}></ProgressBar>
        </div>
        <div className={css.main}>
          <div className={css.header}>
            <div className={css.mainText}>
              {together} <span>{achieve}</span>
            </div>
            <div className={css.languageToggle}>
              {trans('SharedDecision.language')}
            </div>
            <ButtonToggle onToggle={props.langCallback} value={props.lang} parts={buttonParts}></ButtonToggle>
          </div>
          <div className={css.seperator}>&nbsp;</div>
          <div className={css.goals}>
            {goals.map((goal, index) => {
              let style = isSelected(goal.name) ? css.goalBigger : css.goalSmaller;
              return (
                <div key={index} className={style}>
                  <GoalDecision
                    goal={goal}
                    selected={isSelected(goal.name)}
                    onClick={handleGoalSelect(goal.name)}
                    lang={props.lang}
                  ></GoalDecision>
                  <div className={css.goalSpacer}></div>
                  <Confidence level={getLevel(goal.name)} onSelect={handleConfidence(goal.name)} lang={props.lang}></Confidence>
                </div>
          )})}
          </div>
          <div className={css.footerSpacer}></div>
          <div className={css.footer}>
            <Button onClick={handleNext} disabled={!canContinue()} overrideDisable={true}>Next</Button>  
          </div>
        </div>
      </div>
      <Alert open={alertOpen} onClose={handleAlertClose} title={alertTitle} message={alertMessage}></Alert>
    </div>
  );
}

SharedDecision.defaultProps = {
  firstName: 'Jerome',
  appData: {},
  goals: [],
};

export default SharedDecision;