import React from 'react';

import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import css from './ProviderSatisfaction.module.css';


const useStyles = makeStyles({
  radio: {
    padding: 0,
    '&.Mui-checked': {
      color: '#27AE60',
    }
  },
  radioGroup: {
    width: '100%',
    position: 'relative',
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: "'Source Sans Pro', sans-serif",
      position: 'absolute',
      bottom: -19,
      whiteSpace: 'nowrap',
    }
  },
  labelEnd: {
    flexGrow: 1,
    flexBasis: 0,
  },
  label: {
    flexGrow: 1,
    flexBasis: 0,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 16px)',
      height: 1,
      top: 11,
      right: 'calc(50% + 8px)',
      backgroundColor: '#CECECE',
      zIndex: 0,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 16px)',
      height: 1,
      top: 11,
      left: 'calc(50% + 8px)',
      backgroundColor: '#CECECE',
      zIndex: 0,
    },
    '& .MuiRadio-root': {
      zIndex: 100,
    }
  },
});

function ProviderSatisfaction(props) {
  const classes = useStyles();
  const handleLevelChange = (event) => {
    if(event.target.value === props.level) {
      props.onLevelSelect('0');
    } else {
      props.onLevelSelect(event.target.value);
    }
  }

  return (
    <div className={css.confidence}>
      <div className={css.wrapper}>
        <div className={css.title}>
          I am satisfied with Nutri for helping me set a nutrition goal with my patient.
        </div>
        <div className={css.select}>
          <FormControl className={classes.radioGroup} component="fieldset">
            <RadioGroup row aria-label="confidence-level" name="confidence-level" value={props.level}>
              <FormControlLabel className={classes.labelEnd} value="1"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label="Strongly Disagree" labelPlacement="bottom" />
              <FormControlLabel className={classes.label} value="2"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label="" labelPlacement="bottom" />
              <FormControlLabel className={classes.label} value="3"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label="" labelPlacement="bottom" />
              <FormControlLabel className={classes.label} value="4"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label="" labelPlacement="bottom" />
              <FormControlLabel className={classes.labelEnd} value="5"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label="Strongly Agree" labelPlacement="bottom" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

ProviderSatisfaction.defaultProps = {
  level: '0',
  onLevelSelect: () => {},
};

export default ProviderSatisfaction;