import React from 'react';
import css from './WhereGoal.module.css';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ComingSoon from './ComingSoon';
import Dismiss from '../icons/dismiss.svg';
import Lightbulb from '../icons/lightbulb.svg';
import Arrow from '../icons/down_curve_arrow.svg';

function WhereGoal(props) {
  // Coming Soon
  const firstName = props.appData.patientData.patient.firstName;
  const [anchor, setAnchor] = React.useState(null);
  const handleClick = (event) => { setAnchor(anchor ? null : event.currentTarget); };
  const handleClickAway = (event) => { event.preventDefault(); setAnchor(null); };
  const internalHandleOnClickAway = (event) => { setAnchor(null); props.onClickAway(event); };

  return (
    <React.Fragment>
      <div className={props.anchorRef ? css.blackout : null}></div>
      <Popper
        className = {css.popper}
        open={Boolean(props.anchorRef)}
        anchorEl={props.anchorRef}
        placement={props.placement}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          offset: {
            offset:'64,44',
          }
        }}
      >
        <ClickAwayListener onClickAway={internalHandleOnClickAway}>
          <div className={css.whereGoal}>
            <div className={css.arrow}>
              <img src={Arrow} alt="pointer_arrow" />
            </div>
            <div className={css.lightbulb}>
              <img src={Lightbulb} alt="lightbulb_icon" />
            </div>
            <div className={css.title}>
              Where did these goals come from?
            </div>
            <div className={css.message}>
              The 9 goals below are based on the USDA Dietary Guidelines for Americans. 
              Nutri has prioritized which goals need the most improvement based on {firstName}’s 24-hour dietary recall. 
              A 24-hour dietary recall is a single day snapshot  of a person's diet. 
              You can read more about how these goals were selected and prioritized <span onClick={handleClick}>here</span>.
            </div>
            <div className={css.dimiss} onClick={internalHandleOnClickAway}>
              <img src={Dismiss} alt="dismiss_icon" />
            </div>
            <ComingSoon 
              anchorRef={anchor} title={'Coming Soon!'}
              message={'Explanation of how Nutri selects its goals.'}
              onClickAway={handleClickAway} blackout={false}
            ></ComingSoon>
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
}

WhereGoal.defaultProps = {
  anchorRef: null,
  onClickAway: () => {},
  placement: 'top',
  appData: {},
};

export default WhereGoal;