import css from './Button.module.css';

function Button(props) {
  const disabled = props.overrideDisable ? false : props.disabled;
  const classes = `${css.button} ${props.disabled ? css.disabled : ''}`;
  return (
    <button onClick={props.onClick} className={classes} disabled={disabled}>{props.children}</button>
  );
}

Button.defaultProps = {
  text: 'Next',
  disabled: false,
  overrideDisable: false,
};

export default Button;