import React from 'react';
import css from './Asa24Signin.module.css';

import helpers from '../logic/helpers';
import NutriApi from '../logic/NutriApi';
import NutriLogo from '../icons/nutri_logo.svg';


function Asa24Signin(props) {
  const trans = helpers.getTrans('Asa24Signin');
  const [showMessage, setShowMessage] = React.useState(true);

  React.useEffect(() => {
    let func = async () => {
      try {
        await new Promise((resolve, reject) => setTimeout(() => resolve(), 10000));
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const token = params.token;
        const res = await NutriApi.sso(token);
        window.location = res.data;
      } catch(err) {
        console.log(err);
        setShowMessage(false);
      }
    };
    func();
  }, []);

  return (
    <div className={css.message}>
      <img className={showMessage ? css.spin : null} src={NutriLogo} alt='nutri-logo' />
      <br />
      {showMessage ? trans.loggingTitle.eng : trans.errorTitle.eng}
      <br /> <br />
      {showMessage ? trans.loggingTitle.spa : trans.errorTitle.spa}
    </div>
  );
}

export default Asa24Signin;