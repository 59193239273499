import React from 'react';

import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import css from './Confidence.module.css';
import helpers from '../logic/helpers';


const useStyles = makeStyles({
  radio: {
    padding: 0,
    '&.Mui-checked': {
      color: '#27AE60',
    }
  },
  radioGroup: {
    width: '100%',
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: "'Source Sans Pro', sans-serif",
      position: 'absolute',
      bottom: -19,
      whiteSpace: 'nowrap',
    }
  },
  labelCenter: {
    flexGrow: 1,
    flexBasis: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 'calc(50% - 48px)',
      height: 1,
      top: 11,
      left: 38,
      backgroundColor: '#CECECE',
      zIndex: 0,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 'calc(50% - 48px)',
      height: 1,
      top: 11,
      right: 38,
      backgroundColor: '#CECECE',
      zIndex: 0,
    },
    '& .MuiRadio-root': {
      zIndex: 100,
    }
  },
});

function formatStatement(statement) {
  if(!statement)
    return null;
    
  let parts = statement.split('|');
  let result = [];
  for(let i = 0; i < parts.length; ++i)
    if(i % 2 === 0)
      result.push(<span key={i}>{parts[i]}</span>);
    else
      result.push(<span key={i} className={css.bold}>{parts[i]}</span>);
  return result;
}

function Confidence(props) {
  // Handle Lang
  const trans = (path) => helpers.getTrans(path)[props.lang] || '';
  const classes = useStyles();
  const handleLevelChange = (event) => {
    if(event.target.value === props.level) {
      props.onSelect('0');
    } else {
      props.onSelect(event.target.value);
    }
  }

  return (
    <div className={css.confidence}>
      <div className={css.wrapper}>
        <div className={css.title}>{trans('Confidence.confidence')}</div>
        <div className={css.body}>
          {formatStatement(trans('Confidence.nextTime'))}
        </div>
        <div className={css.select}>
          <FormControl className={classes.radioGroup} component="fieldset">
            <RadioGroup row aria-label="confidence-level" name="confidence-level" value={props.level}>
              <FormControlLabel 
                className={classes.label} value="1"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label={trans('Confidence.not')} labelPlacement="bottom" />
              <FormControlLabel className={classes.labelCenter} value="2"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label={trans('Confidence.somewhat')} labelPlacement="bottom" />
              <FormControlLabel className={classes.label} value="3"
                control={<Radio className={classes.radio} onClick={handleLevelChange} />}
                label={trans('Confidence.very')} labelPlacement="bottom" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

Confidence.defaultProps = {
  level: '0',
  lang: 'eng',
  onSelect: () => {},
};

export default Confidence;