import css from './Goal.module.css';


const icons = {
  goal1: require('../icons/fruits_and_vegtables_green.svg').default,
  goal2: require('../icons/whole_grain_green.svg').default,
  goal3: require('../icons/lean_protein_green.svg').default,
  goal4: require('../icons/fat_green.svg').default,
  goal5: require('../icons/vege_oil_green.svg').default,
  goal6: require('../icons/added_sugar_drink_green.svg').default,
  goal7: require('../icons/added_sugar_food.svg').default,
  goal8: require('../icons/calories_green.svg').default,
  goal9: require('../icons/grains_and_startches_green.svg').default,
}

function Goal(props) {
  const bigClassName = props.big ? css.big : '';
  const levelText = props.levelMap[props.goal.level].text;
  const levelClassName = css[props.levelMap[props.goal.level].color];
  const selectedClassName = props.showBorder ? props.selected ? css.selected : '' : '';
  const icon = icons[props.goal.name];
  const showGoal = [1, 2, 3].includes(props.goal.level) ? true : false;

  let statement = '';
  if([1, 2, 3].includes(props.goal.level))
    statement = props.goal.problemStatement;
  else if(props.goal.level === 4)
    statement = props.goal.level4Statement;
  else if(props.goal.level === 5)
    statement = props.goal.level5Statement;
  else if(props.goal.level === 0)
    statement = props.goal.warningStatement;

  return (
    <div className={`${css.goal} ${bigClassName} ${selectedClassName}`} onClick={props.showBorder ? props.onClick : null}>
      <div>
        <div className={`${css.level} ${levelClassName}`}>{levelText}</div>
      </div>
      <div className={css.content}>
        <div className={css.title}>
          <img src={icon} alt="food-type-icon" />
          <span>{props.goal.title}</span>
        </div>
        <div className={css.body}>{statement}</div>
      </div>
      {showGoal ? (
        <div className={css.footer}>
          Goal: <span>{props.goal.problemGoal}</span>
        </div>
      ) : ''}
      
    </div>
  );
}

Goal.defaultProps = {
  big: false,
  levelMap: {
    5: {text: 'Goal Achieved', color: 'green'},
    4: {text: 'Almost there', color: 'blue'},
    3: {text: 'Halfway to goal', color: 'yellow'},
    2: {text: 'Needs work', color: 'orange'},
    1: {text: 'Needs significant work', color: 'red'},
    0: {text: 'Warning', color: 'black'},
  },
  goal: {
    name: 'goal1',
    level: 0,
    title: 'This a title',
    problemStatement: 'Bob has a problem.',
    problemGoal: 'Bob should do this goal',
    level4Statement: 'Bob has a level 4.',
    level5Statement: 'Bob has a level 5.',
    warningStatement: 'Bob has a warning statement',
    selected: false,
  },
  showBorder: true,
  onClick: () => {},
  selected: false,
};

export default Goal;