import React from 'react';
import css from './Completed.module.css';

import ProviderSatisfaction from './ProviderSatisfaction';
import CrossIcon from '../icons/cross_white.svg';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';


const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    }
  },
});


function Completed(props) {
  const classes = useStyles();
  const noteRef = React.useRef();

  const handleCopy = (event) => {
    noteRef.current.select();
    document.execCommand('copy');
    document.getSelection().removeAllRanges();
  }

  return (
    <Dialog className={classes.root} onClose={props.onClose} 
      aria-labelledby="customized-dialog-title" open={props.open} fullWidth={true} maxWidth = {'md'}>
      <div className={css.title}>
        {props.title}
        <img src={CrossIcon} alt="close_icon" onClick={props.onClose} />
      </div>
      <div className={css.content}>
        <div className={css.message}>{props.message}</div>
        <hr className={css.separator} />
        <div className={css.copy} onClick={handleCopy}>Copy</div>
        <br />
        <textarea ref={noteRef} className={css.note} value={props.soapNote} readOnly></textarea>
        <ProviderSatisfaction level={props.level} onLevelSelect={props.onLevelSelect}/>
      </div>  
    </Dialog>
  );
}

Completed.defaultProps = {
  open: false,
  title: 'You’ve completed the Nutri workflow!',
  message: 'Coming Soon: Nutri will automatically send the note to NextGen. While we finish building Nutri, please copy the note to the patient’s EHR by using the ‘copy’ button below',
  soapNote: 'This is a note',
  onClose: () => {},
};

export default Completed;