import css from './ButtonSmall.module.css';

function ButtonSmall(props) {
  return (
    <button onClick={props.onClick} className={css.button} disabled={props.disabled}>{props.children}</button>
  );
}

ButtonSmall.defaultProps = {
  text: 'Edit',
  disabled: false,
};

export default ButtonSmall;