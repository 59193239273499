import Tania from './tania.json';
import Richard from './richard.json';


let labtest = {
  getPatientData: (token) => {
    if(token === '6078744183')
      return Tania;
    else if(token === '3814478706')
      return Richard;
  },
};

export default labtest;