import React from 'react';
import css from './GoalDecision.module.css';

import LearnMore from './LearnMore';
import helpers from '../logic/helpers';

import Dialog from '@material-ui/core/Dialog';

import targetIcon from '../icons/target_icon.svg';
const icons = {
  goal1: require('../icons/fruits_and_vegtables_green.svg').default,
  goal2: require('../icons/whole_grain_green.svg').default,
  goal3: require('../icons/lean_protein_green.svg').default,
  goal4: require('../icons/fat_green.svg').default,
  goal5: require('../icons/vege_oil_green.svg').default,
  goal6: require('../icons/added_sugar_drink_green.svg').default,
  goal7: require('../icons/added_sugar_food.svg').default,
  goal8: require('../icons/calories_green.svg').default,
  goal9: require('../icons/grains_and_startches_green.svg').default,
}

function formatStatement(statement) {
  // Splitting on | means that every odd index
  // in the list is the quoted part of the statement
  let parts = statement.split('|');
  let result = [];
  for(let i = 0; i < parts.length; ++i)
    if(i % 2 === 0)
      result.push(<span key={i}>{parts[i]}</span>);
    else
      result.push(<span key={i} className={css.boldFont}>{parts[i]}</span>);
  return result;
}

function sliceFoodList(foodList) {
  // At most, we can have 5 lines of 45 characters long
  let linesLeft = 5;
  let result = [];
  for(let x of foodList) {
    let numLines = Math.ceil(x.length / 45.0);
    linesLeft -= numLines;
    if(linesLeft >= 0)
      result.push(x);
  }
  return result;
}

function GoalDecision(props) {
  // Handle Lang
  const trans = (path) => helpers.getTrans(path)[props.lang] || '';

  const levelMap = {
    5: {text: trans('GoalLevels.level5'), color: 'green'},
    4: {text: trans('GoalLevels.level4'), color: 'blue'},
    3: {text: trans('GoalLevels.level3'), color: 'yellow'},
    2: {text: trans('GoalLevels.level2'), color: 'orange'},
    1: {text: trans('GoalLevels.level1'), color: 'red'},
    0: {text: trans('GoalLevels.level0'), color: 'black'},
  };

  const levelText = levelMap[props.goal.level].text;
  const levelClassName = css[levelMap[props.goal.level].color];
  const selectedClassName = props.selected ? css.selected : '';
  const icon = icons[props.goal.name];
  let foodList = props.lang === 'spa' ? props.goal.foodListSpa : props.goal.foodListEng || [];
  foodList = sliceFoodList(foodList);

  if(foodList.length === 0)
    foodList.push(props.goal.noFoodList);

  let statement = '';
  if([1, 2, 3].includes(props.goal.level))
    statement = formatStatement(props.goal.quantProblemStatement);
  else if(props.goal.level === 4)
    statement = props.goal.level4Statement;
  else if(props.goal.level === 5)
    statement = props.goal.level5Statement;
  else if(props.goal.level === 0)
    statement = props.goal.warningStatement;

  const [openLearnMore, setOpenLearnMore] = React.useState(false)
  const handleClick = (event) => { setOpenLearnMore(true) };
  const handleClickAway = (event) => { setOpenLearnMore(false) };

  return (
    <div className={`${css.goal} ${selectedClassName}`} onClick={props.onClick}>
      <div>
        <div className={`${css.level} ${levelClassName}`}>{levelText}</div>
      </div>
      <div>
        <div className={css.title}>
          <img src={icon} alt="food-type-icon" />
          <span>{props.goal.title}</span>
        </div>
      </div>
      <div className={css.content}>
        <div className={css.result}>{statement}</div>
        <div className={css.boldFont}>{trans('GoalDecison.recommend')}</div>
        <div className={css.goalList}>
          <img src={targetIcon} alt="target-icon" />
          {formatStatement(props.goal.problemGoalBold)}
          <div className={css.learnMore}>
            <button onClick={handleClick}>{trans('GoalDecison.learn')}</button>
          </div>
        </div>
        <hr className={css.separator} />
        <div className={css.resultList}>
          <span className={css.bold}>{props.goal.dietListTitle}</span>
          <div>
            {foodList.map((item, index) => <div key={index}><span className={css.bulletGreen}></span>{item}</div> )}
          </div>
        </div>
        <Dialog PaperComponent={'div'} onClose={handleClickAway} aria-labelledby="customized-dialog-title" open={openLearnMore} maxWidth={false}>
          <LearnMore lang={props.lang} goal={props.goal} items={props.goal.learnMore} onDismiss={handleClickAway} />
        </Dialog>
      </div>
    </div>
  );
}

GoalDecision.defaultProps = {
  lang: 'eng',
  levelMap: {
    5: {text: 'Goal Achieved', color: 'green'},
    4: {text: 'Almost there', color: 'blue'},
    3: {text: 'Halfway to goal', color: 'yellow'},
    2: {text: 'Needs work', color: 'orange'},
    1: {text: 'Needs significant work', color: 'red'},
    0: {text: 'Warning', color: 'black'},
  },
  firstName: 'Jerome',
  selected: false,
  onClick: () => {},
  goal: {
    name: 'goal1',
    level: 0,
    title: 'This a title',
    problemStatement: 'Bob has a problem.',
    problemGoal: 'Bob should do this goal',
    level4Statement: 'Bob has a level 4.',
    level5Statement: 'Bob has a level 5.',
    warningStatement: 'Bob has a warning statement',
    dietListTitle: 'Top Eaty Things included in Bob\'s diet:',
    learnMore: [
      {
        item: 'What are fruits and vegetables?',
        message: 'Fruits and vegetables can be fresh, canned, frozen, or dried.'
      },
      {
        item: 'Why should I make 1/2 my plate fruits and vegetables?',
        message: 'Eating fruits and vegetables contributes to a healthy diet. They can reduce risk for heart disease and protect against certain types of cancers.'
      },
      {
        item: 'How can I get started?',
        message: 'Add fruits and vegetables to each meal. For example, add leftover vegetables to breakfast.'
      }
    ],
    foodList: [
      'Pizza',
      'Place',
    ]
  },
};

export default GoalDecision;