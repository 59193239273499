import React from 'react';
import css from './RecallResults.module.css';

import Navbar from '../components/Navbar';
import ProgressBar from '../components/ProgressBar';
import Goal from '../components/Goal';
import Button from '../components/Button';
import Summary from '../components/Summary';
import WhereGoal from '../components/WhereGoal';
import Alert from '../components/Alert';

import helpers from '../logic/helpers';


function RecallResults(props) {
  // Set Title
  React.useEffect(() => { document.title = 'Recall Results'; });

  const firstName = props.appData.patientData.patient.firstName;
   // Where Goal
  const goalCardsRef = React.useRef();
  const [anchor, setAnchor] = React.useState(null);
  const [showBorder, setShowBorder] = React.useState(true);
  const handleClickAway = (event) => { setAnchor(null); setShowBorder(true); };
  const handleSetAnchor = (event) => { setAnchor(goalCardsRef.current); setShowBorder(false); };

  // Handle Goal Selecting
  const bigGoals = props.appData.goals.slice(0, 3);
  const smallGoals = props.appData.goals.slice(3);
  const [selectedGoals, setSelectedGoals] = React.useState([]);
  const handleGoalSelect = (name) => () => {
    if(selectedGoals.includes(name))
      setSelectedGoals(selectedGoals.filter(item => item !== name));
    else {
      if(selectedGoals.length === 3)
        return handleMoreSelected();
      setSelectedGoals(selectedGoals.concat([name]));
    }
  };
  const isSelected = (name) => {
    return selectedGoals.includes(name);
  };

  // Next Button
  const canContinue = () => {
    return selectedGoals.length > 1;
  };
  const handleNext = (event) => {
    if(!canContinue())
      return handleMoreSelectedError();
    else {
      helpers.sendStatus('recallPage.next', 'clicked');
      helpers.sendStatus('recallPage.next.goals', JSON.stringify(selectedGoals));
      props.callback(selectedGoals);
    }
  };
  
  // Alert
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const handleAlertOpen = (title, message) => (event) => { setAlertTitle(title); setAlertMessage(message); setAlertOpen(true); };
  const handleAlertClose = () => setAlertOpen(false);
  const handleMoreSelected = handleAlertOpen('Attention!', 'You’re only allowed to select 3 goals, please unselect a previously selected goal if you’d like to add this one');
  const handleMoreSelectedError = handleAlertOpen('Error', 'Please select 2 to 3 goals to continue');

  // Handle Session Storage setting
  React.useEffect(() => {
    const goals = JSON.parse(sessionStorage.getItem('recallResult')) || [];
    setSelectedGoals(goals);
  }, []);

  React.useEffect(() => {
    helpers.sendStatus('recallPage', 'loaded');
  }, []);

  return (
    <div className={css.container}>
      <div>
        <Navbar></Navbar>
      </div>
      <div className={css.content}>
        <div>
          <ProgressBar appData={props.appData}></ProgressBar>
        </div>
        <div className={css.main}>
          <div className={css.goals}>
            <div className={css.selectGoals}>
              <p>
                <span className={css.bold}>Select up to three nutrition goals</span> that would be important for {firstName} to work on. &nbsp;
                On the next screen, you and {firstName} will discuss these goals and select ONE goal for them to work towards. &nbsp;
                <span className={css.link} onClick={handleSetAnchor}>Where did the goals come from?</span>
              </p>
            </div>
            <hr className={css.seperator}/>
            <div className={css.goalCards} ref={goalCardsRef}>
              <div className={css.mainGoals}>
                <span>Based on {firstName}'s 24-hour dietary recall, Nutri identified that these 3 goals need the most improvement:</span>
                <div className={css.flex}>
                  {bigGoals.map((item, index) => 
                    <Goal key={index} big={true} goal={item} selected={isSelected(item.name)} showBorder={showBorder} onClick={handleGoalSelect(item.name)}></Goal>
                  )}
                </div>
              </div>
              <div className={css.subGoals}>
                <p>Nutri has identified these goals as lower priority for {firstName}:</p>
                <div className={css.flex}>
                  {smallGoals.map((item, index) => 
                    <Goal key={index} goal={item} selected={isSelected(item.name)} showBorder={showBorder} onClick={handleGoalSelect(item.name)}></Goal>
                  )}
                </div>
              </div>
              
            </div>
            <WhereGoal appData={props.appData} anchorRef={anchor} onClickAway={handleClickAway}></WhereGoal>
            <div className={css.footerSpacer}></div>
            <div className={css.footer}>
              <span className={css.nextText}>
                (Please select at least 2 goals to continue) &nbsp;
              </span>
              <Button overrideDisable={true} disabled={!canContinue()} onClick={handleNext}>Next</Button>
            </div>
          </div>
          <div className={css.summary}>
            <Summary appData={props.appData}></Summary>
          </div>
        </div>
      </div>
      <Alert open={alertOpen} onClose={handleAlertClose} title={alertTitle} message={alertMessage}></Alert>
    </div>
  );
}

RecallResults.defaultProps = {
  firstName: 'Jerome',
  selectGoalCallback: (goalType, index) => console.log(`Goal Clicked ${goalType} ${index}`),
  callback: () => {},
};

export default RecallResults;