import React from 'react';
import css from './Preview.module.css';
import TakeHome from './TakeHome';
import closeIcon from '../icons/cross_white.svg';
import printIcon from '../icons/print.svg';

import helpers from '../logic/helpers';

import ButtonToggle from './ButtonToggle';

import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: { overflowY: 'unset' },
}));

function Preview(props) {
  const firstName = props.appData.patientData.patient.firstName;

  // Take Home
  const [takeHomeOpen, setTakeHomeOpen] = React.useState(false);
  const handleTakeHome = (event) => { setTakeHomeOpen(true); };
  const handleTakeHomeClose = () => setTakeHomeOpen(false);

  const handlePrintClick = () => { 
    helpers.sendStatus('reviewPage.preview.print', 'clicked');
    window.print(); 
  };

  const classes = useStyles();

  return (
    <div className={css.panel}>
      <div className={css.header}>
        <div className={css.title}>PREVIEW: {firstName}'s Take Home Materials</div>
        <div className={css.toggleText}>Print in:</div>
        <ButtonToggle onToggle={props.langCallback} value={props.lang}></ButtonToggle>
        <div className={css.printIcon} onClick={handlePrintClick}><img src={printIcon} alt="print-icon" /></div>
      </div>
      <div className={css.content}>
        <div className={css.subtext}>
          <p> Below is a summary sheet of {firstName}'s Nutri Visit and information on achieving their selected goal. </p>
        </div>
        <div className={css.takehomeWrapper}>
          <div className={css.preview}>
            <div className={css.enlargePreview} onClick={handleTakeHome}>
              <p>Enlarge Preview</p>
            </div>
            <div className={`${css.previewWrapper} ${css.dialogWrapper}`} onClick={handleTakeHome}>
              <div className={css.previewScale}>
                <div className={css.boxShadow}>
                  <TakeHome appData={props.appData} goal={props.goal} lang={props.lang}></TakeHome>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.materials}>
        <div className={css.materialsComment}>Browse addtional materials (This feature coming soon)</div>
      </div>
      <Dialog classes={{ paper: classes.paper}} onClose={handleTakeHomeClose} open={takeHomeOpen}
        aria-labelledby="customized-dialog-title" maxWidth={"xl"}>
        <div className={css.closeDialog} onClick={handleTakeHomeClose}><img src={closeIcon} alt="close preview"/></div>
        
        <div className={`${css.dialogWrapper} ${css.printFullsize}`}>
          <div className={css.printScale}>
            <TakeHome appData={props.appData} goal={props.goal} lang={props.lang}></TakeHome>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

Preview.defaultProps = {
};

export default Preview;