import React from 'react';
import css from './ButtonToggle.module.css';


function ButtonToggle(props) {
  const toggle = props.value === props.parts[1].value ? true : false;
  const handleToggle = (toggle) => (event) => { 
    let result = toggle ? props.parts[1].value : props.parts[0].value;
    props.onToggle(result);
  };

  const partOneCss = `${css.part} ${!toggle ? css.selected : ''}`;
  const partTwoCss = `${css.part} ${toggle ? css.selected : ''}`;
  
  return (
    <div className={css.buttonToggle}>
      <div className={partOneCss} onClick={handleToggle(false)}>{props.parts[0].display}</div>
      <div className={partTwoCss} onClick={handleToggle(true)}>{props.parts[1].display}</div>
    </div>
  );
}

ButtonToggle.defaultProps = {
  parts: [
    { display: 'English', value: 'eng' },
    { display: 'Spanish', value: 'spa' }
  ],
  onToggle: () => {},
};

export default ButtonToggle;
