import React from 'react';
import './App.css';

import {
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";

import RecallResults from './pages/RecallResults';
import SharedDecision from './pages/SharedDecision';
import Review from './pages/Review';
import Loading from './components/Loading';
import Asa24Signin from './pages/Asa24Signin';
import Asa24Callback from './pages/ASA24Callback';

function App() {
  const history = useHistory();

  const [appData, setAppData] = React.useState(JSON.parse(sessionStorage.getItem('appData')));
  const loadingCallback = (data) => {
    setAppData(data);
    sessionStorage.setItem('token', data.token);
    history.push('/recall');
  };
  const [recallResult, setRecallResult] = React.useState(JSON.parse(sessionStorage.getItem('recallResult')));
  const recallCallback = (result) => {
    if(JSON.stringify(result) !== JSON.stringify(recallResult)) {
      sessionStorage.setItem('recallResult', JSON.stringify(result));
      sessionStorage.removeItem('sharedResult');
      setSharedResult(null);
    }
    setRecallResult(result);
    history.push('/shared');
  };
  const [sharedResult, setSharedResult] = React.useState(JSON.parse(sessionStorage.getItem('sharedResult')))
  const sharedCallback = (result) => {
    sessionStorage.setItem('sharedResult', JSON.stringify(result));
    setSharedResult(result);
    history.push('/review');
  };
  const [lang, setLang] =  React.useState(sessionStorage.getItem('sharedResult'));
  const langCallback = (result) => {
    sessionStorage.setItem('lang', result);
    setLang(result);
  };

  // Handle Session setting
  React.useEffect(() => {
    setAppData(JSON.parse(sessionStorage.getItem('appData')) || null);
    setRecallResult(JSON.parse(sessionStorage.getItem('recallResult')) || null);
    setSharedResult(JSON.parse(sessionStorage.getItem('sharedResult')) || null);
    setLang(sessionStorage.getItem('lang') || 'eng');
  }, []);
  
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact={true}>
          <Loading callback={loadingCallback}></Loading>
        </Route>
        <Route path="/recall">
          { appData ? <RecallResults appData={appData} callback={recallCallback}></RecallResults> : <Redirect to="/" /> }
        </Route>
        <Route path="/shared">
          { appData && recallResult ? 
            <SharedDecision 
              appData={appData}
              goals={recallResult}
              lang={lang}
              langCallback={langCallback}
              callback={sharedCallback}
            ></SharedDecision> :
            <Redirect to="/recall" />
          }
        </Route>
        <Route path="/review">
          { appData && sharedResult ?
            <Review
              appData={appData}
              goal={sharedResult}
              lang={lang}
              langCallback={langCallback}
            ></Review> :
            <Redirect to="/shared" /> }
        </Route>
        <Route path="/asa24-signin">
          <Asa24Signin></Asa24Signin>
        </Route>
        <Route path="/asa24-callback">
          <Asa24Callback></Asa24Callback>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
