import React from 'react';
import css from './Review.module.css';
import ReviewPanel from '../components/ReviewPanel';
import ClinicalNote from '../components/ClinicalNote';
import Preview from '../components/Preview';
import Completed from '../components/Completed';

import helpers from '../logic/helpers';

import Button from '../components/Button';

import Navbar from '../components/Navbar';
import ProgressBar from '../components/ProgressBar';
import TakeHome from '../components/TakeHome';

const confidenceMap = {
  '0': '"unsure"',
  '1': '"not at All" confident',
  '2': '"somewhat" confident',
  '3': '"very" confident',
};

function Review(props) {
  const firstName = props.appData.patientData.patient.firstName;
  const goal = props.appData.goals.find((goal) => goal.name === props.goal.goal);
  const confidence = confidenceMap[props.goal.confidence[props.goal.goal] || '0'];
  let note = goal.soapNote;
  if(confidence !== confidenceMap['0']) {
    let confidenceStatement = helpers.replaceAll(goal.soapConfidence, '[confidence]', confidence);
    note = helpers.replaceAll(note, '[confidence]', confidenceStatement);
  } else
    note = helpers.replaceAll(note, '[confidence]', '');

  const [soapNote, setSoapNote] = React.useState(note);

  const handleSoapEdit = (note) => { setSoapNote(note); };

  // Complete
  const [completedOpen, setCompletedOpen] = React.useState(false);
  const handleCompleted = (event) => { 
    helpers.sendStatus('reviewPage.complete', 'clicked');
    setCompletedOpen(true);
  };
  const handleCompletedClose = () => setCompletedOpen(false);

  // Provider Satisfaction
  const [satisfactionLevel, setSatisfactionLevel] = React.useState('0');
  const handleLevelSelect = (level) => {
    setSatisfactionLevel(level);
    let toSend = {
      providerId: props.appData.patientData.patient.providerId,
      level: level
    };
    helpers.sendStatus('reviewPage.complete.satisfactionLevel', JSON.stringify(toSend));
  };

  React.useEffect(() => {
    helpers.sendStatus('reviewPage', 'loaded');
  }, []);

  return (
    <React.Fragment>
      <div className={css.container}>
        <div>
          <Navbar></Navbar>
        </div>
        <div className={css.content}>
          <div>
            <ProgressBar step={2} appData={props.appData}></ProgressBar>
          </div>
          <div className={css.main}>
            <div className={css.text}>
              <p>
                <span className={css.bold}>Click print</span> to share patient education materials with {firstName}.
                <span className={css.bold}> Click complete</span> to add the note to {firstName}'s EHR.
              </p>
            </div>
            <div className={css.middle}>
              <div className={css.left}>
                <div className={css.reviewWrapper}>
                  <ReviewPanel appData={props.appData} goal={props.goal}></ReviewPanel>
                </div>
                <div className={css.clinicWrapper}>
                  <ClinicalNote soapNote={soapNote} callback={handleSoapEdit}></ClinicalNote>
                </div>
              </div>
              <div className={css.right}>
                <div className={css.previewWrapper}>
                  <Preview
                    appData={props.appData}
                    goal={props.goal}
                    lang={props.lang}
                    langCallback={props.langCallback}
                  ></Preview>
                </div>
                <div className={css.footer}>
                  <Button onClick={handleCompleted}>Complete</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Completed 
          open={completedOpen}
          soapNote={soapNote}
          onClose={handleCompletedClose}
          level={satisfactionLevel}
          onLevelSelect={handleLevelSelect}
        ></Completed>
      </div>
      <div className={css.printTakehome}>
        <div className={css.printScale}>
          <TakeHome appData={props.appData} goal={props.goal} lang={props.lang}></TakeHome>
        </div>
      </div>
    </React.Fragment>
  );
}

Review.defaultProps = {
  appData: {},
  goal: {},
};

export default Review;