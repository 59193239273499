import React from 'react';
import css from './Loading.module.css';
import NutriLogo from '../icons/nutri_logo.svg';
import helpers from '../logic/helpers';


function Loading(props) {
  const [error, setError] = React.useState(false); 

  React.useEffect(() => {
    let getData = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if(!params.token) {
        let appData = JSON.parse(sessionStorage.getItem('appData'));
        if(appData) {
          props.callback(appData);
          return;
        }
      }

      sessionStorage.clear();

      let patientData = await helpers.getInformationSimulate(params.token);
      if(!patientData)
        return setError(true);
      let proessedGoals = helpers.processGoals(patientData.patient, patientData.rulesEngine);
      let goals = proessedGoals.goals;
      let goalsSpa = proessedGoals.goalsSpa;
      let result = {patientData, goals, goalsSpa, token: params.token};
      console.log(result);
      sessionStorage.setItem('appData', JSON.stringify(result));

      if(result)
        props.callback(result);
      else
        setError(true);
    };
    getData();
  }, [props]);

  return (
    <div className={css.loading}>
      <img className={error ? null : css.rotate} src={NutriLogo} alt="nutri_logo" />
      <div>{error ? props.error : props.message}</div>
    </div>
  );
}

Loading.defaultProps = {
  message: 'Loading',
  error: 'Invalid Token',
  callback: () => {},
};

export default Loading;