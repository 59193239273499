import React from 'react';
import css from './Navbar.module.css';

import NutriLogo from '../icons/nutri_logo.svg';
import DashboardIcon from '../icons/dashboard_icon.svg';
import MaterialsIcon from '../icons/materials_icon.svg';
import SettingsIcon from '../icons/settings_icon.svg';
import ComingSoon from './ComingSoon';


function Navbar(props) {
  const [anchor, setAnchor] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const handleClick = (title, message) => (event) => { setTitle(title); setMessage(message); setAnchor(anchor ? null : event.currentTarget); };
  const handleClickAway = (event) => { setAnchor(null); };

  const handleDashboardClick = handleClick('Coming Soon!', 'View a list of your other patients using Nutri');
  const handleMaterialsClick = handleClick('Coming Soon!', 'View your library of nutrition education materials');
  const handleSettingsClick = handleClick('Coming Soon!', 'Change Nutri\'s settings');

  return (
    <div className={css.navbar}>
      <img className={css.logo} src={NutriLogo} alt="nutri nav icon" />
      <div className={css.nav}>
        <img onClick={handleDashboardClick} src={DashboardIcon} alt="dashboard nav icon" />
        <br />
        Dashboard
      </div>
      <div className={css.nav}>
        <img onClick={handleMaterialsClick} src={MaterialsIcon} alt="materials nav icon" />
        <br />
        Materials
      </div>
      <div className={css.settings}>
        <img onClick={handleSettingsClick} src={SettingsIcon} alt="settings icon" />
      </div>
      <ComingSoon anchorRef={anchor} title={title} message={message} onClickAway={handleClickAway}></ComingSoon>
    </div>
  );
}

export default Navbar;