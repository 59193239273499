import { useHistory } from "react-router-dom";
import css from './ProgressBar.module.css';

function getListClass(index, step) {
  const active = index > step ? '' : css.active;
  const activeText = index === step ? css.activeText : '';
  const check = index < step ? css.check : '';
  const clickable = index <= step ? css.clickable : '';
  return `${active} ${check} ${activeText} ${clickable}`;
}

function ProgressBar(props) {
  const history = useHistory();
  const firstName = props.appData.patientData.patient.firstName;
  const lastName = props.appData.patientData.patient.lastName;
  const display = `${lastName}, ${firstName}`;

  const goToStep = (item) => (event) => {
    if(item.step < props.step)
      history.push(item.url);

    // uncomment this if we want to allow people to go forward in timeline
    // if(JSON.parse(sessionStorage.getItem(item.sessionKey)))
    //   history.push(item.url);
  };

  return (
    <div className={css.progress}>
      <div className={css.flex}>
        <ul>
          {props.steps.map((item, index) =>
            <li key={index} className={getListClass(index, props.step)} onClick={goToStep(item)}>
              <span onClick={goToStep(item)}>{item.label}</span>
            </li>
          )}
        </ul>
      </div>
      <div className={`${css.flex} ${css.name}`}>
        {display}
      </div>
      
    </div>
  );
}

ProgressBar.defaultProps = {
  step: 0,
  steps: [
    { label: 'Diet Recall Results', step: 0, url: '/recall', sessionKey: 'appData' },
    { label: 'Shared Decision Making', step: 1, url: '/shared', sessionKey: 'recallResult' },
    { label: 'Review', step: 2, url: '/review', sessionKey: 'sharedResult' },
  ],
};

export default ProgressBar;