import React from 'react';
import css from './ClinicalNote.module.css';
import ButtonSmall from '../components/ButtonSmall';


function ClinicalNote(props) {
  const [edit, setEdit] = React.useState(false);
  const handleEditClick = () => { setEdit(!edit); }
  const handleEdit = (e) => { props.callback(e.target.value); };

  return (
    <div className={css.panel}>
      <div className={css.content}>
        <div className={css.title}>
          <span>{props.title}</span>
        </div>

        <span>
          <div className={css.subtitle}>
            <span>{props.subtitle}</span>
            {
              edit ? 
              <ButtonSmall onClick={handleEditClick}>Save</ButtonSmall> :
              <ButtonSmall onClick={handleEditClick}>Edit</ButtonSmall>
            }
          </div>
        </span>
        <div className={css.textarea}>
          <textarea className={css.note} value={props.soapNote} onChange={handleEdit} disabled={!edit}></textarea>
        </div>
      </div>
    </div>
  );
}

ClinicalNote.defaultProps = {
  title: 'Clinical Note',
  subtitle: "This text will be added to the visit note for today's appointment",
};

export default ClinicalNote;