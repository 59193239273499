import React from 'react';
import css from './ComingSoon.module.css';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


function ComingSoon(props) {
  const [arrowRef, setArrowRef] = React.useState(null);
  

  return (
    <React.Fragment>
      {props.blackout ? <div className={props.anchorRef ? css.blackout : null}></div> : null}
      <Popper
        className = {css.comingSoon}
        open={Boolean(props.anchorRef)}
        anchorEl={props.anchorRef}
        placement={props.placement}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
          offset: {
            offset:'-27,10',
          }
        }}
      >
        
        <span className={css.arrow} ref={setArrowRef}></span>
        <ClickAwayListener onClickAway={props.onClickAway}>
          <div className={css.wrapper}>
            <div className={css.title}>{props.title}</div>
            <div className={css.message}>{props.message}</div>
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
}

ComingSoon.defaultProps = {
  anchorRef: null,
  placement: 'right',
  onClickAway: () => {},
  title: 'Coming Soon!',
  message: 'This is coming soon!',
  blackout: true,
};

export default ComingSoon;