import labtest from '../labtest/labtest';
import goals from './goals.json';
import goalsSpa from './goals_spa.json';
import translations from './translations.json';
import NutriApi from './NutriApi';

function processGoalsHelper(patient, asa24Data, goal) {
  const fields = ['problemStatement', 'level4Statement', 'level5Statement', 'level4Statement', 'noFoodList', 'soapConfidence',
    'warningStatement', 'quantProblemStatement', 'intake', 'recommendedLow', 'recommendedHigh', 'soapNote', 'dietListTitle'];
  for(let field of fields) {
    // console.log('field------------------', field)
    goal[field] = processGoalEntry(patient, asa24Data, goal, goal[field]);
  }
  const learnMoreFields = ['learnMore', 'learnMore2'];
  for(let field of learnMoreFields) {
    for(let i = 0; i < goal[field].length; ++i) {
      goal[field][i].item = processGoalEntry(patient, asa24Data, goal, goal[field][i].item);
      // goal[field][i].message = processGoalEntry(patient, asa24Data, goal, goal[field][i].message);
    }
  }

  let foodListEng = asa24Data[`engList_${goal.name}`]
  if (typeof foodListEng === 'string' || foodListEng instanceof String) {
    // convert foodList into an array
    let foodListString = foodListEng.toLowerCase();
    if(foodListString && foodListString.length > 2 &&
      foodListString[0] === '[' && foodListString[foodListString.length - 1]) {
        goal.foodListEng = JSON.parse(helpers.replaceAll(foodListString, "'", '"'));
        goal.foodListEng = Array.from(new Set(goal.foodListEng));
    } else {
      goal.foodListEng = [];
    }
  } else {
    goal.foodListEng = foodListEng;
  }

  let foodListSpa = asa24Data[`spanList_${goal.name}`]
  if (typeof foodListSpa === 'string' || foodListSpa instanceof String) {
    // convert foodList into an array
    let foodListString = foodListSpa.toLowerCase();
    if(foodListString && foodListString.length > 2 &&
      foodListString[0] === '[' && foodListString[foodListString.length - 1]) {
        goal.foodListSpa = JSON.parse(helpers.replaceAll(foodListString, "'", '"'));
        goal.foodListSpa = Array.from(new Set(goal.foodListSpa));
    } else {
      goal.foodListSpa = [];
    }
  } else {
    goal.foodListSpa = foodListSpa;
  }
}

function processGoalEntry(patient, asa24Data, goal, entry) {
  // console.log('entry____________', entry);
  if(!entry)
    return entry;
  // console.log('toReplace', [...entry.matchAll(/\[([^[])+\]/g)].map(item => item[0]));
  let newEntry = entry;
  // Run through all know potential replacements
  // [firstname]
  newEntry = helpers.replaceAll(newEntry, '[firstname]', patient.firstName);
  // [goal]
  newEntry = helpers.replaceAll(newEntry, '[goal]', goal.problemGoal);
  // [goal]
  newEntry = helpers.replaceAll(newEntry, '[goal_lower]', goal.problemGoal.toLowerCase());
  // [title]
  newEntry = helpers.replaceAll(newEntry, '[title]', goal.title);
  // [title_lower]
  newEntry = helpers.replaceAll(newEntry, '[title_lower]', goal.title.toLowerCase());
  // [consumed_goal1]
  let consumedGoal1 = Math.round(parseFloat(asa24Data.consumed_goal1));
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal1]', consumedGoal1);
  // [consumed_goal9]
  let consumedGoal9 = Math.round(parseFloat(asa24Data.consumed_goal9));
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal9]', consumedGoal9);
  // [consumed_goal9/consumed_goal8]
  let eightintonine = parseFloat(asa24Data.consumed_goal9)/parseFloat(asa24Data.consumed_goal8);
  eightintonine = Math.round(eightintonine * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal9/consumed_goal8]', eightintonine);
  // '[consumed_goal8]', '[target_goal8]'
  let consumedGoal8 = Math.round(parseFloat(asa24Data.consumed_goal8));
  let targetGoal8 = Math.round(parseFloat(asa24Data.target_goal8));
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal8]', consumedGoal8);
  newEntry = helpers.replaceAll(newEntry, '[target_goal8]', targetGoal8);
  // [consumed_goal7/consumed_goal8]
  let eightintoseven = parseFloat(asa24Data.consumed_goal7)/parseFloat(asa24Data.consumed_goal8);
  eightintoseven = Math.round(eightintoseven * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal7/consumed_goal8]', eightintoseven);
  // [consumed_goal4/consumed_goal8]
  let eightintofour = parseFloat(asa24Data.consumed_goal4)/parseFloat(asa24Data.consumed_goal8);
  eightintofour = Math.round(eightintofour * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal4/consumed_goal8]', eightintofour);
  // [consumed_goal6/consumed_goal8]
  let eightintosix = parseFloat(asa24Data.consumed_goal6)/parseFloat(asa24Data.consumed_goal8);
  eightintosix = Math.round(eightintosix * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal6/consumed_goal8]', eightintosix);
  // [consumed_goal5 / (consumed_goal5 + consumed_solidfats)]
  let percentfats = parseFloat(asa24Data.consumed_goal5)/(parseFloat(asa24Data.consumed_goal5) + parseFloat(asa24Data.consumed_solidfats));
  percentfats = Math.round(percentfats * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal5 / (consumed_goal5 + consumed_solidfats)]', percentfats);
  // [consumed_goal3/consumed_protein]
  let percentprotein = parseFloat(asa24Data.consumed_goal3)/parseFloat(asa24Data.consumed_protein);
  percentprotein = Math.round(percentprotein * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal3/consumed_protein]', percentprotein);
  // [consumed_goal2/consumed_grains]
  let percentgrains = parseFloat(asa24Data.consumed_goal2)/parseFloat(asa24Data.consumed_grains);
  percentgrains = Math.round(percentgrains * 100);
  newEntry = helpers.replaceAll(newEntry, '[consumed_goal2/consumed_grains]', percentgrains);
  // [target_goal1]
  let targetGoal1 = Math.round(parseFloat(asa24Data.target_goal1));
  newEntry = helpers.replaceAll(newEntry, '[target_goal1]', targetGoal1);
  // [target_goal9]
  let targetGoal9 = Math.round(parseFloat(asa24Data.target_goal9));
  newEntry = helpers.replaceAll(newEntry, '[target_goal9]', targetGoal9);
  // [quantprobstatement]
  let quantProblemStatement = helpers.replaceAll(goal.quantProblemStatement, '|', '');
  newEntry = helpers.replaceAll(newEntry, '[quantprobstatement]', quantProblemStatement);

  // Misc
  // If Fruits and Vegetables has "|1 cups|" replace "cups" with "cup"
  newEntry = helpers.replaceAll(newEntry, '|1 cups|', '|1 cup|');

  // console.log('toReplace left', [...newEntry.matchAll(/\[([^[])+\]/g)].map(item => item[0]));
  return newEntry;
} 


let helpers = {
  getInformationSimulate: async (token) => {
    return new Promise((resolve, reject) => {
      // Simulate time it takes to get data from backend
      setTimeout(() => {
        if(token === '6078744183' || token === '3814478706')
          resolve(labtest.getPatientData(token));
        else {
          // resolve(null);
          // resolve(labtest.getPatientData('6078744183'));

          NutriApi.rulesEngine(token)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            console.log(err);
            resolve(null);
          });
        }
      }, 3000);
    });
  },
  getInformation: async (token) => {
    return new Promise((resolve, reject) => {
      // Simulate time it takes to get data from backend
      NutriApi.rulesEngine(token)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        resolve(null);
      });
    });
  },
  processGoals: (patient, asa24Data) => {
    // Get Goals Template and make a deep copy
    let processedGoals = JSON.parse(JSON.stringify(goals));
    let processedGoalsSpa = JSON.parse(JSON.stringify(goalsSpa));
    // Fill in Goals Template with Asa24 data
    for(let goal in processedGoals) {
      processGoalsHelper(patient, asa24Data, processedGoals[goal]);
    }
    for(let goal in processedGoalsSpa) {
      processGoalsHelper(patient, asa24Data, processedGoalsSpa[goal]);
    }
    // Get priority order of Goals and add in levels
    let result = {
      goals: [{},{},{},{},{},{},{},{},{}],
      goalsSpa: [{},{},{},{},{},{},{},{},{}]
    };
    for(let i = 1; i < 10; ++i) {
      processedGoals[`goal${i}`].level = parseInt(asa24Data[`level_goal${i}`]);
      processedGoalsSpa[`goal${i}`].level = parseInt(asa24Data[`level_goal${i}`]);
      let pos = asa24Data[`position_goal${i}`] ? parseInt(asa24Data[`position_goal${i}`]) - 1 : i - 1;
      result.goals[pos] = processedGoals[`goal${i}`];
      result.goalsSpa[pos] = processedGoalsSpa[`goal${i}`];
    }
    return result;
  },
  getTrans: (path) => {
    let pathParts = path.split('.');
    let trans = translations;
    for(let i = 0; i < pathParts.length; ++i)
      trans = trans ? trans[pathParts[i]] : null;
    return trans;
  },
  getWords: (path, lang='eng') => {
    let trans = helpers.getTrans(path);
    return trans[lang] || '';
  },
  sendStatus: (name, value) => {
    const token = sessionStorage.getItem('token') || '';
    NutriApi.updateStatus(token, name, value).then(() => {});
  },
  replaceAll: (str, match, val) => {
    let regWithEscapes = new RegExp(match.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g');
    return str.replace(regWithEscapes, val);
  },
};

export default helpers;