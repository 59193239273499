import css from './LearnMore.module.css';

import dismissIcon from '../icons/dismiss_white.svg';
import helpers from '../logic/helpers';


// function formatBold(text) {
//   let parts = text.split('|');
//   let result = [];
//   for(let i = 0; i < parts.length; ++i) {
//     if(i % 2 === 0)
//       result.push(<span key={i}>{parts[i]}</span>);
//     else
//       result.push(<span key={i} className={css.bold}>{parts[i]}</span>);
//   }
//   return result;
// }

function formatBox(text) {
  let parts = text.split('\n');
  let result = [];
  for(let i = 0; i < parts.length; ++i) {
    let temp = parts[i];
    temp = helpers.replaceAll(temp, '(up)', '↑');
    temp = helpers.replaceAll(temp, '(down)', '↓');
    //  •
    let indent = temp.includes('***') ? css.indentThree : temp.includes('**') ? css.indentTwo : temp.includes('*') ? css.indentOne : null;
    temp = helpers.replaceAll(temp, '*', '');
    temp = <div key={i} className={indent}>{indent ? '• ' : null}{temp}</div>;
    result.push(temp);
  }
  return result;
}

function LearnMore(props) {
  const trans = (path) => helpers.getTrans(path)[props.lang] || '';

  return (
    <div className={css.learnMore}>
      <div className={css.title}>
        {trans('GoalDecison.learn')}
        <img onClick={props.onDismiss} src={dismissIcon} alt="dismiss_icon" />
      </div>
      <div className={css.content}>
        <div className={css.goal}>
          {trans('GoalDecison.recommend')} <span className={css.bold}>{props.goal.problemGoal}</span>
        </div>
        <div className={css.table}>
          {props.goal.learnMore2.map((entry, index) => 
            <div key={index} className={css.box}>
              <div className={css.tableTitle}>{entry.item}</div>
              <div className={css.tableContent}>{formatBox(entry.message)}</div>
            </div>
          )}
        </div>
      </div>
      {/* <div className={css.wrapper}>
        <ul className={css.list}>
          {props.items.map((item, index) =>
            <li key={index}>
              <span className={css.itemTitle}>{item.item}</span>
              <br />
              <div className={css.itemMessage}>{item.message}</div>
              <br />
            </li>
          )}
        </ul>
      </div> */}
    </div>
  );
}

LearnMore.defaultProps = {
  items: [],
  lang: 'eng',
};

export default LearnMore;
