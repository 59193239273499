import css from './TakeHome.module.css';

import icon from '../icons/nutri_logo_text.png';
import person from '../icons/lady_sitting.svg';

import helpers from '../logic/helpers';


function formatStatement(statement) {
  // Splitting on | means that every odd index
  // in the list is the quoted part of the statement
  let parts = statement.split('|');
  let result = [];
  for(let i = 0; i < parts.length; ++i)
    if(i % 2 === 0)
      result.push(<span key={i}>{parts[i]}</span>);
    else
      result.push(<span key={i} className={css.bold}>{parts[i]}</span>);
  return result;
}


function TakeHome(props) {
  const dateLocale = props.lang === 'spa' ? 'es-ES' :  'en-US';
  const date = (new Date()).toLocaleDateString(dateLocale, {year: 'numeric', month: 'long', day: 'numeric'});
  const firstName = props.appData.patientData.patient.firstName;
  const lastName = props.appData.patientData.patient.lastName;
  const name = `${firstName} ${lastName}`;

  // Handle Lang
  const trans = (path) => helpers.getTrans(path)[props.lang] || '';

  const titleCss = `${css.title} ${props.lang === 'spa' ? css.titleSpa : ''}`;

  const confidenceMap = {
    '0': trans('Confidence.unsure').toLowerCase(),
    '1': trans('Confidence.not').toLowerCase() + ' ' + trans('Confidence.confident').toLowerCase(),
    '2': trans('Confidence.somewhat').toLowerCase() + ' ' + trans('Confidence.confident').toLowerCase(),
    '3': trans('Confidence.very').toLowerCase() + ' ' + trans('Confidence.confident').toLowerCase(),
  };
  let goal;
  if(props.lang === 'spa')
    goal = props.appData.goalsSpa.find((goal) => goal.name === props.goal.goal);
  else
    goal = props.appData.goals.find((goal) => goal.name === props.goal.goal);
  const confidence = confidenceMap[props.goal.confidence[props.goal.goal] || '0'];
  const confidenceStatement = trans('Takehome.confidence').replace('[confidence]', confidence);

  return (
    <div className={css.takeHome}>
      <div className={css.header}>
        <div>
          <img src={icon} alt="nutri_logo" />
        </div>
        <div className={titleCss}>{trans('Takehome.summary')}</div>
        <div className={css.for}>
          {trans('Takehome.for')} <span className={css.bold}>{name}</span>
        </div>
      </div>
      <div className={css.goal}>
        <div className={css.goalPerson}><img src={person} alt="person_image" /></div>
        <div className={css.goalWrapper}>
          <div className={css.goalBorder}></div>
          <div className={css.goalBox}>
            <div className={css.goalTitle}>{trans('Takehome.goal')}</div>
            {/* <div className={css.goalText}>Make 1/2 of your grains <span className={css.bold}>whole grains</span></div> */}
            <div className={css.goalText}>{goal.problemGoal}</div>
            <div className={css.goalDescription}>{trans('Takehome.chose')}</div>
          </div>
        </div>
        <div className={css.goalWhat}>
          <div className={css.goalWhatTitle}>{goal.learnMore[0].item}</div>
          <div className={css.goalWhatText}>{goal.learnMore[0].message}</div>
        </div>
      </div>
      <div className={css.help}>
        <div className={css.helpLeft}>
          <div className={css.helpTitle}>{goal.learnMore[1].item}</div>
          <div className={css.helpBody}>{goal.learnMore[1].message}</div>
        </div>
        <div className={css.helpSeparator}></div>
        <div className={css.helpRight}>
          <div className={css.helpTitle}>{goal.learnMore[2].item}</div>
          <div className={css.helpBody}>{goal.learnMore[2].message}</div>
        </div>
      </div>
      <div className={css.confidence}>
        <div className={css.confidenceTitle}>{trans('Takehome.statement')}</div>
        <div className={css.confidenceLevel}>
          {formatStatement(confidenceStatement)}
        </div>
        <div className={css.achieve}>
          <div className={css.achieveBox}></div>
          <div className={css.achieveTitle}>{trans('Takehome.help')}</div>
        </div>
      </div>
      <div className={css.footer}>
        <div className={css.footerUrl}>nutri.dellmed.utexas.edu</div>
        <div className={css.footerDate}>{date}</div>
      </div>
    </div>
  );
}

TakeHome.defaultProps = {
  appData: {},
  preview: false,
  lang: 'eng',
};

export default TakeHome;